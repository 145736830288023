@import '../../../utils/_responsive';
@import '../../../utils/_units';
@import '../../../utils/theme';


.wrapper {
    opacity: 0;
    
    &.visible {
        animation: fadein 2s;
        opacity: 1;
    }
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
}

.home {
    background: $theme-5;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: auto;

    &.blur {
        // filter: blur(2px); 
        .panel-profile {
            @include desktop {
                width: 0%;
                padding: 0;
                overflow: auto;
            }
        }

        .panel-experience {
            @include mobile {
                opacity: 0;
            }
            @include desktop {
                transition: all 1500ms ease;
            }
            // filter: blur(4px);
            
        }
    }

    &.animate {
        .panel-profile {
            transition: all 1500ms ease 800ms;
            opacity: 1;
            padding: 32px;
            @include desktop {
                width: 1000px;
            }
        }
        .panel-experience {
            @include mobile {
                transition: all 1500ms ease 800ms;
                opacity: 1;
            }
        }
        .skills-percentage {
            .skill {
                &::before {
                    display: block;
                    content: "";
                    height: 10px;
                    width: 30%;
                    background-color: $theme-2;
                    position: absolute;
                    bottom: $unit-small - 5px;
                    z-index: 2;
                }
                &::after { 
                    display: block;  
                    content: "";
                    position: relative;
                    top: 5px;
                    height: 10px;
                    width: 100%;
                    background-color: $theme-3;
                    z-index: 0;
                }
                $time: 1500ms;
                $delay: 2500ms;
                &:nth-child(1)::before {width:70%;animation: skill_1 $time ease $delay;}
                &:nth-child(2)::before {width:80%;animation: skill_2 $time ease $delay;} 
                &:nth-child(3)::before {width:70%;animation: skill_3 $time ease $delay;}
                &:nth-child(4)::before {width:80%;animation: skill_4 $time ease $delay;}
                &:nth-child(5)::before {width:55%;animation: skill_5 $time ease $delay;}
                &:nth-child(6)::before {width:60%;animation: skill_6 $time ease $delay;}
                &:nth-child(7)::before {width:35%;animation: skill_7 $time ease $delay;}
            }
            @keyframes skill_1 {from {width:0%;}to {width:70%;}}
            @keyframes skill_2 {from {width:0%;}to {width:80%;}}
            @keyframes skill_3 {from {width:0%;}to {width:70%;}}
            @keyframes skill_4 {from {width:0%;}to {width:80%;}}
            @keyframes skill_5 {from {width:0%;}to {width:55%;}}  
            @keyframes skill_6 {from {width:0%;}to {width:60%;}}
            @keyframes skill_7 {from {width:0%;}to {width:35%;}}
        }
        @include desktop {
            height: calc(100vh - #{$unit-large} * 2);
            transition: all 1000ms ease;
            overflow: inherit;
        }
    }
    
    .section {
        padding: $unit-large;
    }

    @include desktop {
        flex-direction: row;
    }
    
    .panel-profile {
        background-color: $theme-1;
        color:black;
        height: 100%;
        @include desktop {
            width: 100%;
        }

        .profile-header {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            .profile-pic {
                border-radius : 50%;
                height: 140px;
                width: 140px;
                margin: $unit-default;
                object-fit: cover;
            }
    
            .title {
                color: $theme-3;
                font-size: 2rem;
                text-transform: uppercase;
            }
        }

        .profile-content {
            .contact {
                padding: $unit-default 0;
                .contact-info {
                    max-width: 400px;
                    display:flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    .type {
                        margin-right: auto;
                        color: $theme-3;
                        font-size: 20px;
                    }
                    .data {
                        font-size: 20px;
                    }
                }
            }
            
            .blurb {
                padding: $unit-default 0;
            }
    
            .social {
                width:100%;
                top:85%;
                display:flex;
                justify-content:center;
                flex-wrap: wrap;
                .link {
                    margin: 14px;
                    display:inline-block;
                }
                .icon {
                    width:50px;
                    height:50px;
                    border-radius: 50%;
                    background-size: 55%;
                    background-position: center center;

                    &:hover{
                        transition: all .3s ease;
                        background-color: $theme-2;
                        box-shadow: 0px 5px 5px 0px $theme-2;
                        transform: translateY(-5px);
                    }
                }
            }
        }
    } 

    .panel-experience {
        @include desktop {
            transition: all 1200ms ease;
        }
        
        background-color:$theme-5;
        color: white;
        display: flex;
        flex-direction: column;
        
        @include desktop {
            min-height: 100%;
            overflow-y: auto;
        }
        .title {
            color: $theme-2;
            text-transform: uppercase;
        }
        .experience-row {
            padding: $unit-default 0;
            padding-bottom: 0;
            .oppurtunity {
                padding: $unit-default 0;
                padding-top: 0;
                .company-wrapper {
                    padding: $unit-small 0;
                    display: flex;
                    flex-direction :row;
                    .company {
                    }
                    .role {
                        margin: 0 $unit-default;
                    }
                }
                .description-wrapper {
                    padding: $unit-small 0;
                    display: flex;
                    flex-direction :row;
                    .timeline {
                        min-width: 70px;
                        width: 70px;
                        font-size: 13px;
                        color: $theme-3;
                    }
                    .description {
                        margin: 0 $unit-default;
                    }
                }
            }
        }
        .skill-row {
            display: flex;
            flex-direction: row;
            height: auto;
            padding: $unit-default 0;
            padding-top: 0;
            @include mobile {
                flex-wrap: wrap;
            }
            .skills {
                padding: $unit-default;
                padding-top: 0;
                padding-left: 0;
                width: 40%;
                @include mobile {
                    padding: $unit-default 0;
                    width: 100%;
                }
                
                .skills-percentage {
                    list-style-type: none;
                    padding: 0;
                    .skill {
                        width: 100%;
                        position: relative;
                        padding: $unit-small 0;
                    }
                }
            }
            .hobbies {
                padding: $unit-default;
                padding-top: 0;
                padding-right: 0;
                width: 60%;
                @include mobile {
                    padding: $unit-default 0;
                    width: 100%;
                }
                .description {
                    margin-top: 1.2em;
                    width: 100%;
                    padding-top: 5px;
                    .hobbie-image-container {
                        overflow: hidden;
                        width: 100%;
                        height: 105px;
                        position: relative;
                        margin: $unit-default 0;
                        
                        .hobbie-image {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                            filter: grayscale(100%);
                            transition: all 400ms ease-out;
                            &:hover {
                                filter:none;
                                -webkit-filter:none;
                                transform: scale(1.1);
                                overflow: hidden;
                            }
                            &.image-two {
                                object-position: 50% 40%;
                            }
                        }
                        &::before {
                            content: attr(data-before);
                            padding: 4px 14px;
                            position: absolute;
                            top: 0px;
                            display: block;
                            background-color: $theme-3;
                            z-index: 90;
                        }
                    }
                }
            }
        }
    }

    .invisBttn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
}
